import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi, I'm Ouss!
    </Text>
    <p>{`Also known as @CloudOuss, a proud Dad & Application Development and Digital Transformation Solutions Architect. Worked across the complete software development lifecycle.
Currently assisting orgs in delivering their Next-Gen Architectures. Passionate about bridging the gap between IT and Business.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      